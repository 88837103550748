import { datadogRum } from '@datadog/browser-rum';
import { call } from 'typed-redux-saga';
import {
  sessionApiClient,
  usersApiClient,
  falconFrontendEventsApiClient,
} from '@owl-frontend/api-client';
import { asyncActionStateMatchers, invoke } from '@owl-frontend/redux';
import sessionSlice from './interface';

if (RUM_APPLICATION_ID && RUM_CLIENT_TOKEN) {
  datadogRum.init({
    env: ENV,
    version: __version,
    applicationId: RUM_APPLICATION_ID,
    clientToken: RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'falcon',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100, // if not included, the default is 100
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.setGlobalContextProperty('viewport', {
    width: window.screen.width,
    height: window.screen.height,
  });

  datadogRum.startSessionReplayRecording();
}

// eslint-disable-next-line require-yield
function* logout() {
  datadogRum.stopSessionReplayRecording();
  yield* call(sessionApiClient.session.logoutFalcon);
}

function* refresh() {
  return yield* call(sessionApiClient.session.refresh);
}

const slice = sessionSlice
  .addAsyncSagas({
    current: invoke(usersApiClient.users.falconCurrent),
    logFrontendEvents: invoke(falconFrontendEventsApiClient.log),
    logout,
    refresh,
  })
  .addReducers({
    'current/fulfilled': (state, action) => {
      if (action.payload) {
        const { username, name, email, roles } = action.payload;

        datadogRum.setUser({
          id: username,
          name,
          email,
          roles,
        });
      }
      const roles = action.payload.roles ?? [];
      state.current = {
        ...action.payload,
        permission: roles.reduce((prev, curr) => {
          return {
            ...prev,
            [curr]: true,
          };
        }, {}),
      };
    },
  });

export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
