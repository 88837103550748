import {
  TypedUseSelectorHook,
  useSelector as useSelectorRedux,
} from 'react-redux';
import { ingestionSlice } from '@owl-frontend/components';
import { OwlStore } from '@owl-frontend/redux';
import dossiersUploadSlice from '../pages/dossiers-upload/data/logic';
import feedbackSlice from '../pages/feedback/data/logic';
import fowlSlice from '../pages/fowl/data/logic';
import fulfillmentCapacitySlice from '../pages/fulfillment-capacity/data/logic';
import rolesSlice from '../pages/roles/data/logic';
import tenantDetailsSlice from '../pages/tenant-details/data/logic';
import tenantsSlice from '../pages/tenants/data/logic';
import usersSlice from '../pages/users/data/logic';
import workersSlice from '../pages/workers/data/logic';
import localizationSlice from './localization/logic';
import sessionSlice from './session/logic';
import settingsSlice from './settings/logic';
import storeOptions from './storeOptions';

const store = new OwlStore([], {}, storeOptions);
// pages
store.addSlice(tenantsSlice);
store.addSlice(tenantDetailsSlice);
store.addSlice(dossiersUploadSlice);
store.addSlice(ingestionSlice);
store.addSlice(workersSlice);
store.addSlice(fowlSlice);
store.addSlice(usersSlice);
store.addSlice(rolesSlice);
store.addSlice(feedbackSlice);
// meta
store.addSlice(localizationSlice);
store.addSlice(sessionSlice);
store.addSlice(settingsSlice);
store.addSlice(fulfillmentCapacitySlice);
export default store as OwlStore<any> as OwlStore<StoreState>;

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('../pages/tenants/data/logic', () =>
    store.addSlice(tenantsSlice, true)
  );
  module.hot.accept('../pages/tenant-details/data/logic', () =>
    store.addSlice(tenantDetailsSlice, true)
  );
  module.hot.accept('../pages/dossiers-upload/data/logic', () =>
    store.addSlice(dossiersUploadSlice, true)
  );
  module.hot.accept('../pages/workers/data/logic', () =>
    store.addSlice(workersSlice, true)
  );
  module.hot.accept('../pages/fowl/data/logic', () =>
    store.addSlice(fowlSlice, true)
  );
  module.hot.accept('../pages/users/data/logic', () =>
    store.addSlice(usersSlice, true)
  );
  module.hot.accept('../pages/roles/data/logic', () =>
    store.addSlice(rolesSlice, true)
  );
  module.hot.accept('./localization/logic', () =>
    store.addSlice(localizationSlice, true)
  );
  module.hot.accept('./session/logic', () =>
    store.addSlice(sessionSlice, true)
  );
  // // NOTE: if we self-register slices, in the slice's module, we would do the following:
  // if (process.env.NODE_ENV === 'development' && module.hot) {
  //   module.hot.accept()
  //   module.hot.dispose((data) => data.update = true)
  // }
  // store.addSlice(slice, module.hot?.data.update)
}

// NOTE: we could infer this from the `addSlice` operations, but imo it's
// better to explicitly state things. and we should infer it from the addSlice
// operations, but a few explicit things makes "go to definition" and other
// such things work nicer. also, it'll let us note the state if we move slices
// from being registered here to self-registering (and also allow
// hot-reloading stores?)
export interface StoreState {
  [tenantsSlice.name]: (typeof tenantsSlice)['initialState'];
  [tenantDetailsSlice.name]: (typeof tenantDetailsSlice)['initialState'];
  [dossiersUploadSlice.name]: (typeof dossiersUploadSlice)['initialState'];
  [workersSlice.name]: (typeof workersSlice)['initialState'];
  [fowlSlice.name]: (typeof fowlSlice)['initialState'];
  [usersSlice.name]: (typeof usersSlice)['initialState'];
  [rolesSlice.name]: (typeof rolesSlice)['initialState'];
  [localizationSlice.name]: (typeof localizationSlice)['initialState'];
  [sessionSlice.name]: (typeof sessionSlice)['initialState'];
  [ingestionSlice.name]: (typeof ingestionSlice)['initialState'];
}

export const useSelector: TypedUseSelectorHook<StoreState> = useSelectorRedux;
