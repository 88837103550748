import { generatePath as generatePathRR } from 'react-router-dom';
import type { Empty } from '@owl-frontend/components';
import { CombineObjectType, enumSet } from '@owl-lib/type-util';
import type { GeneratePathParam } from '../../helpers/route-types';

export enum RouteDefs {
  Main = '/',
  Tenants = '/tenants',
  TenantDetails = '/tenants/:tenantId',
  TenantUserDetails = '/tenant-users/:tenantId',
  DossiersUpload = '/tenants/:tenantId/files-upload',
  DossiersPreview = '/tenants/:tenantId/preview-upload',
  Tasks = '/tasks',
  ClaimantInfo = '/tasks/claimant-info',
  Users = '/users',
  FowlTopologies = '/fowl/topologies',
  FulfillmentCapacity = '/fulfillment-capacity',
  Feedback = '/feedback',
  TenantUsers = '/tenant-users',
}

export const routeSet = enumSet(RouteDefs);

// example
// type LoginRouteParams = ExtractRouteParams<RootRoute.Login>

type GeneratePathRest<Params> = Empty extends Params
  ? [params?: Params]
  : [params: Params];
type TypedGeneratePath = <R extends string>(
  path: R,
  ...rest: GeneratePathRest<CombineObjectType<GeneratePathParam<R>>>
) => string;
export const generatePath = generatePathRR as TypedGeneratePath;
