import { loadable } from '@owl-frontend/components';

// if you get a typing error in this file, you probably need to define your page parts.

export const TenantDetailsPage = loadable(
  () => import('./tenant-details/TenantDetailsLandingContainer'),
  {
    parts: {
      topPanelActions: true,
      breadcrumbs: true,
    },
  }
);
export const TenantUserDetailsPage = loadable(
  () => import('./tenant-users/TenantUserDetailsLandingContainer'),
  {
    parts: {
      topPanelActions: true,
      breadcrumbs: true,
    },
  }
);

export const DossiersUploadPage = loadable(
  () => import('./dossiers-upload/DossiersUploadContainer'),
  {
    parts: {
      topPanelActions: true,
      breadcrumbs: true,
    },
  }
);

export const TenantsPage = loadable(
  () => import('./tenants/TenantsContainer'),
  {
    parts: {
      breadcrumbs: true,
    },
  }
);

export const TasksPage = loadable(() => import('./workers/TasksContainer'), {
  parts: {
    topPanelActions: true,
    breadcrumbs: true,
  },
});

export const UsersPage = loadable(() => import('./users/UsersContainer'), {
  parts: {
    breadcrumbs: true,
  },
});

export const TenantUsersPage = loadable(
  () => import('./tenant-users/TenantUsersContainer'),
  {
    parts: {
      breadcrumbs: true,
    },
  }
);

export const FowlTopologiesPage = loadable(
  () => import('./fowl/TopologiesContainer'),
  {
    parts: {
      breadcrumbs: true,
    },
  }
);

export const FulfillmentCapacityPage = loadable(
  () => import('./fulfillment-capacity/FulfillmentCapacityContainer'),
  {
    parts: {
      breadcrumbs: true,
    },
  }
);

export const FeedbackPage = loadable(
  () => import('./feedback/FeedbackContainer'),
  {
    parts: {
      breadcrumbs: true,
    },
  }
);

export const FeedbackAdminPage = loadable(
  () => import('./feedback/FeedbackAdminContainer'),
  {
    parts: {
      breadcrumbs: true,
    },
  }
);
